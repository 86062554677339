<template>
    <div class="p-grid crud-demo">
        <Loader v-model="loading" />
        <div class="p-col-12">
            <div class="card">
                <HelperDialog v-model="prospectHelper" header="Buscar Prospecto" :headers="prospectHeaders" :rows="entities" @selected="selectProspect" />
                <HelperDialog v-model="sellerHelper" header="Buscar Vendedor" :headers="sellerHeaders" :rows="sellers" @selected="selectSeller" /> 
                <HelperDialog v-model="referenceHelper" header="Buscar Referencia" :headers="referenceHeaders" :rows="references" @selected="selectReference" />
                <HelperDialog v-model="branchHelper" header="Buscar Sucurusal" :headers="branchHeaders" :rows="branches" @selected="selectBranch" />
                <Panel header="Prospecto">
                    <BasicFormToolbar v-if="!view" @new="openNew" @save="save" @refresh="refresh" @list="openTable"
                        :actions="['new', 'save', 'refresh','list']" />
                    <div class="grid formgrid p-fluid">
                        <div class="col-12">
                            <Fieldset :toggleable="true" legend="Informacion General">
                                <div class="p-fluid formgrid grid">
                                    <FormInputText  @search="(prospectHelper.visible = true)" :readonly="true" :search="true"
                                        wrapperClass="field col-1" label="Prospecto" ref="id"
                                        :valid="validate.validations.id" v-model="entity.id" />
                                    <FormInputText @search="(sellerHelper.visible = true)" :readonly="true" :search="true"
                                        wrapperClass="field col-5" label="Vendedor" ref="seller_name"
                                        :valid="validate.validations.seller_name" v-model="entity.seller_name" />
                                    <FormInputText @search="(branchHelper.visible = true)" :search="true"
                                        wrapperClass="field col-3" label="Sucursal" :readonly="true"
                                        :valid="validate.validations.branch_name" v-model="entity.branch_name" />
                                    <FormCalendar :readonly="true" :valid="validate.validations.date_prospect" wrapperClass="field col-3" label="Fecha" dateFormat="dd/mm/yy" ref="date_prospect" v-model="entity.date_prospect" />
                                    <FormInputText @search="(referenceHelper.visible = true)" :readonly="true" :search="true"
                                        wrapperClass="field col-4" label="Referencia"
                                        :valid="validate.validations.reference" v-model="entity.reference" />
                                    <FormInputText wrapperClass="field col-4" label="Nombre de la Empresa"
                                        :valid="validate.validations.prospect_name" v-model="entity.prospect_name" />
                                    <FormInputText wrapperClass="field col-4" label="Persona que brinda la atencion"
                                        :valid="validate.validations.attention" v-model="entity.attention" />
                                    <FormInputText wrapperClass="field col-4" label="Telefono"
                                        :valid="validate.validations.phone_number" v-model="entity.phone_number" />
                                    <FormInputText wrapperClass="field col-4" label="E-mail"
                                        :valid="validate.validations.email" :validation="'Favor de agregar un correo electronico valido'" v-model="entity.email" />
                                    <!-- <FormInputText  @search="(referenceHelper.visible = true)" :search="true"
                                        wrapperClass="field col-4" label="Referencia" ref="reference"
                                        :valid="validate.validations.reference" v-model="entity.reference" /> -->  
                                    <FormInputText wrapperClass="field col-12" label="Comentarios"
                                        :valid="validate.validations.comments" v-model="entity.comments" />
                                </div>
                            </Fieldset>
                        </div>
                        <Dialog
                            v-model:visible="newDialog"
                            :style="{ width: '1200px' }"
                            :modal="true">
                            <div v-if="!modal" class="col-12">
                                <br>
                                <Fieldset :toggleable="true" legend="Registros">
                                    <BasicDatatable :rows="entities" :headers="headers" />

                                </Fieldset>
                            </div>
                        </Dialog>
                        <!-- <div v-if="!modal" class="col-12">
                            <br>
                            <Fieldset :toggleable="true" legend="Resultados">
                                <BasicDatatable :rows="entities" :headers="headers" :rowaction="true" :rowdelete="true"
                                    :rowedit="true" @edited="edit" @deleted="deleteDialog = true" />
                                <DeleteDialog v-model:visible="deleteDialog" @closed="deleteDialog = false"
                                    @deleted="confirmDelete" />
                            </Fieldset>
                        </div> -->
                    </div>
                </Panel>
            </div>
        </div>
    </div>
</template>
  
<script>
import FormInputText from "../../../components/general/FormInputText.vue";
import BasicDatatable from "../../../components/general/BasicDatatable.vue";
import BasicFormToolbar from "../../../components/general/BasicFormToolbar.vue";
// import DeleteDialog from "../../../components/general/DeleteDialog.vue";
// import FormInputNumber from "../../../components/general/FormInputNumber.vue";

import { Prospect } from "../../../models/crm/Prospect";
import { SalesMan } from "../../../models/comercial/SalesMan";
import { Branch } from "../../../models/general/Branch";
import { CustomerReference } from "../../../models/comercial/CustomerReference";

import {
    HeaderGrid,
    Rule,
    validate,
    fillObject,
    Toast,
    ErrorToast,
} from "../../../utilities/General";

import Loader from "../../../components/general/Loader.vue";
import Session from "../../../mixins/sessionMixin";
import FormCalendar from "../../../components/general/FormCalendar.vue";
import HelperDialog from '../../../components/general/HelperDialog.vue';

export default {
    mixins: [Session],
    props: {
        modal: null,
        id: null
    },
    data() {
        return {
            entity: new Prospect(),
            newDialog: false,
            references: [],
            branches: [],
            entities: [],
            sellers: [],
            prospects: [],
            deleteDialog: false,
            rules: [
                new Rule({ name: "branch_name" }),
                new Rule({ name: "date_prospect" }),
                new Rule({ name: "prospect_name" }),
                new Rule({ name: "attention" }),
                new Rule({ name: "phone_number" }),
                new Rule({ name: "email", type: "email" }),
                new Rule({ name: "seller" }),
                new Rule({ name: "seller_name" }),
                new Rule({ name: "reference" }),
                new Rule({ name: "comments" })
            ],
            validate: {
                valid: false,
                validations: {
                    
                    branch_name: null,
                    date_prospect: null,
                    prospect_name: null,
                    attention: null,
                    phone_number: null,
                    email: null,
                    seller: null,
                    seller_name: null,
                    reference: null,
                    comments: null
                },
            },
            headers: [
                new HeaderGrid("Nombre", "prospect_name"),
                new HeaderGrid("Fecha", "date_prospect", {type: "date"}),
                new HeaderGrid("Atencion", "attention"),
                new HeaderGrid("Telefono", "phone_number"),
                new HeaderGrid("Email", "email"),
                new HeaderGrid("Nombre Vendedor", "seller_name"),
                new HeaderGrid("Nombre Referencia", "reference"),
                new HeaderGrid("Comentarios", "comments")
            ],
            prospectHelper: {
                visible: false,
            },
            prospectHeaders: [
                new HeaderGrid("Prospecto", "id"),
                new HeaderGrid("Nombre", "prospect_name")
            ],
            sellerHelper: {
                visible: false,
            },
            sellerHeaders: [
                new HeaderGrid("Vendedor", "id"),
                new HeaderGrid("Nombre", "name")
            ],
            referenceHelper: {
                visible: false,
            },
            referenceHeaders: [
                new HeaderGrid("Referencia", "id"),
                new HeaderGrid("Nombre de la Referencia", "key_name")
            ],
            branchHelper: {
                visible: false,
            },
            branchHeaders: [
                new HeaderGrid("Sucursale", "name")
            ],
            loading: false,
            
        };
    },
    components: { Loader,  FormInputText, FormCalendar, BasicFormToolbar, HelperDialog, BasicDatatable },
    created() {
        this.entity = new Prospect(this.session); 
    },
    async mounted() {
        await this.refresh();
        this.entity.branch_name = await this.entity.getBranch();
        this.entity.seller_name = await this.entity.getSalesMan();
        this.entity.id = await this.entity.getNextId();
    },
    methods: {
        openNew() {
            this.entity = new Prospect(this.session); 
        },
        openTable(){
            this.newDialog = true;
        },
        async  selectProspect(payload) {
            this.entity.id = payload.id;
            this.entity.prospect_name = payload.prospect_name;
            this.entity.branch_name = payload.branch_name;
            this.entity.attention = payload.attention;
            let dateformat = payload.date_prospect;
            let split = dateformat.split('T');
            let split2 = split[0].split('-');
            let splitjoin = split2[2]+"/"+split2[1]+"/"+split2[0];
            let dateobject = new Date(splitjoin)
            this.entity.date_prospect = dateobject;
            this.entity.phone_number = payload.phone_number;
            this.entity.email = payload.email;
            this.entity.seller = payload.seller;
            this.entity.seller_name = payload.seller_name;
            this.entity.reference = payload.reference;
            this.entity.comments = payload.comments;
            },
        selectSeller(payload){
            this.entity.seller = payload.id;
            this.entity.seller_name = payload.name;
        },
        selectReference(payload){
            this.entity.reference = payload.key_name;
        },
        selectBranch(payload){
            this.entity.branch_name = payload.name;
        },
        async save() {
            try {
                let mayorid = await this.entity.getNextId();
                //* Validacion de form
                this.loading = true;
                this.validate = validate(this.entity, this.rules);
                if (!this.validate.valid) {
                    throw "Favor de validar los campos";
                }
                //* Si el id es != 0 entonces actualizamos, si no, guardamos
                if (this.entity.id && this.entity.id > 0 && this.entity.id != mayorid) {
                    //* Actualizamos
                    let entity = await this.entity.update();
                    this.$toast.add({
                        severity: "success",
                        summary: "Actualizar",
                        detail: "Informacion actualizada con exito",
                        life: 3000,
                    });
                    this.$emit('update', entity);
                    //* Modificamos el listado pah
                    let index = this.entities.findIndex((x) => x.id == this.entity.id);
                    this.entities[index] = entity;
                } else {
                    this.entity.id = null;
                    //* Creamos uno nuevo
                    let entity = await this.entity.save();
                    //* Agregamos un dato extra
                    this.$emit('save', entity);
                    this.entities.push(entity);
                    this.$toast.add(
                        new Toast({
                            summary: "Creacion",
                            detail: "Informacion guardada con exito",
                        })
                    );
                }
                this.entity = new Prospect(this.session);
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
            this.entity.branch_name = await this.entity.getBranch();
            this.entity.seller_name = await this.entity.getSalesMan();
            this.entity.id = await this.entity.getNextId();
        },
        edit(entity) {
            this.entity = fillObject(this.entity, entity);
            if ((this.entity.labels ?? '').length > 0) {
                this.entity.array_labels = (this.entity.labels ?? '').split(",");
            }
            this.$refs.nombre.focus();
        },
        confirmDelete(entity) {
            this.entity = fillObject(this.entity, entity);
            if ((this.entity.labels ?? '').length > 0) {
                this.entity.array_labels = (this.entity.labels ?? '').split(",");
            }
            this.deleteDialog = true;
        },
        async deleteEntity() {
            try {
                this.loading = true;
                //* Eliminamos de la base
                await this.entity.delete();
                //* Eliminamos de la vista
                this.entities = this.entities.filter((val) => val.id !== this.entity.id);
                this.deleteDialog = false;
                //* Limpiamos la entidad
                this.entity = new Prospect(this.session);
                this.$toast.add({
                    severity: "success",
                    summary: "Eliminacion",
                    detail: "Registro eliminado con exito",
                    life: 3000,
                });
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
        },
        async refresh() {
            this.loading = true;
            try {
                if (!this.modal)
                    this.entities = await this.entity.all();
                    this.sellers = await new SalesMan(this.session).all();
                    this.branches = await new Branch(this.session).all();
                    this.references = await new CustomerReference(this.session).all();
                if (this.id) {
                    this.entity.id = this.id;
                    let entity = await this.entity.retrieve();
                    this.entity = fillObject(this.entity, entity);
                    if ((this.entity.labels ?? '').length > 0) {
                        this.entity.array_labels = (this.entity.labels ?? '').split(",");
                    }
                }
            } catch (error) {
                this.$toast.add(new ErrorToast(error));
            } finally {
                this.loading = false;
            }
            this.entity.branch_name = await this.entity.getBranch();
            this.entity.seller_name = await this.entity.getSalesMan();
            this.entity.id = await this.entity.getNextId();
        },
    }
};
</script>
  
<style scoped lang="scss">
.table-header {
    display: flex;
    justify-content: space-between;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 960px) {
    ::v-deep(.p-toolbar) {
        flex-wrap: wrap;

        .p-button {
            margin-bottom: 0.25rem;
        }
    }
}
</style>
  