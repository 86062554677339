import axios from "axios";
import {
    Abstract
} from './Abstract';

export class Prospect extends Abstract {
    constructor(session) {
        super('crm/Prospect', session);
        this.id = null;
        this.branch_name = null;
        this.date_prospect = new Date();
        this.prospect_name = null;
        this.attention = null;
        this.phone_number = null;
        this.email = null;
        this.seller = null;
        this.seller_name = null;
        this.reference = null;
        this.comments = null;
    }
    async getBranch() {
        let response = await axios.get(this.controller + "/getBranch", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
      }
    async getSalesMan() {
        let response = await axios.get(this.controller + "/getSalesMan", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch
            }
        });
        return response.data;
    }
    async getNextId() {
        let response = await axios.get(this.controller + "/getNextId", {
            headers: {
                user: this.user,
                company: this.id_company,
                branch: this.id_branch,
            }
        });
        return response.data;
    }
}